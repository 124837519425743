import * as React from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  Edit,
  Create,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';

export const DeckcrudList = props => (
  <List {...props} pagination={false}>
    <Datagrid rowClick="edit">
      <TextField source="partNumber"/>
      <ArrayField source="subCategories">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ArrayField>
      <TextField source="name"/>
      <NumberField source="widthMm"/>
      <NumberField source="lengthMm"/>
      <BooleanField source="defaultForSubCategory"/>
    </Datagrid>
  </List>
);

export const DeckcrudEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="partNumber"/>
      <ArrayInput source="subCategories">
        <SimpleFormIterator>
          <TextInput source="category"/>
          <TextInput source="name"/>
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput multiline source="name"/>
      <NumberInput source="widthMm"/>
      <NumberInput source="lengthMm"/>
      <NumberInput source="thicknessMm"/>
      <NumberInput source="maxCcMm"/>
      <NumberInput source="packageSize"/>
      <TextInput source="screwType"/>
      <BooleanInput source="defaultForSubCategory"/>
    </SimpleForm>
  </Edit>
);

export const DeckcrudAdd = props => (
  <Create title='Create a Post' {...props}>
   <SimpleForm>
    <TextInput source="partNumber"/>
    <TextInput multiline source="name"/>
    <NumberInput source="widthMm"/>
    <NumberInput source="lengthMm"/>
    <NumberInput source="thicknessMm"/>
    <NumberInput source="maxCcMm"/>
    <NumberInput source="packageSize"/>
    <TextInput source="screwType"/>
    <BooleanInput source="defaultForSubCategory"/>
   </SimpleForm>
  </Create>
);
