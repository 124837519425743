export default {
  // called when the user attempts to log in
  login: ({ username, password }) =>  {
    const request = new Request('/auth/token/', {
      method: 'POST',
      body: JSON.stringify({ 'user': username, 'pass': password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.text();
      })
      .then(auth => {
        localStorage.setItem('optimera_admin_auth_token', auth);
      })
      .catch(() => {
        throw new Error('Network error')
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('optimera_admin_auth_token');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('optimera_admin_auth_token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('optimera_admin_auth_token')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};