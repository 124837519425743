import * as React from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  Edit,
  Create,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';

export const ScrewcrudList = props => (
  <List {...props} pagination={false}>
   <Datagrid rowClick="edit">
    <NumberField source="id"/>
    <TextField source="partnumber" lable="Artikelnummer"/>
    <TextField source="name"/>
    <TextField source="corrosionEnvs"/>
    <TextField source="matsToFasteners"/>
    <TextField source="matsAsAnchors"/>
    <NumberField source="maxThickness"/>
   </Datagrid>
  </List>
);
export const ScrewcrudEdit = props => (
  <Edit {...props}>
   <SimpleForm>
	<NumberField source="id" lable="id"/>
    <TextInput source="partnumber" lable="Artikelnummer" />
    <TextInput source="name"/>
    <TextInput source="corrosionEnvs"/>
    <TextInput source="matsToFasteners"/>
    <TextInput source="matsAsAnchors"/>
    <NumberInput source="maxThickness"/>
   </SimpleForm>
  </Edit>
);
export const ScrewcrudAdd = props=>(
 <Create title='Create a Post' {...props}>
  <SimpleForm>
   <TextInput source="partnumber" lable="Artikelnummer"/>
   <TextInput source="name"/>
   <TextInput source="corrosionEnvs"/>
   <TextInput source="matsToFasteners"/>
   <TextInput source="matsAsAnchors"/>
   <NumberInput source="maxThickness"/>
  </SimpleForm>
 </Create>
);
