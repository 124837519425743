import * as React from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  Edit,
  Create,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';

export const RoofcrudList = props => (
	<List {...props} pagination={false}>
		<Datagrid rowClick="edit">
			<NumberField source="id"/>
			<TextField source="partNumber"/>
			<TextField source="category"/>
		    <TextField source="itemAttributes"/>
			<BooleanField source="isDef"/>
			<NumberField source="packSize"/>
		</Datagrid>
	</List>
);

export const RoofcrudEdit = props => (
  <Edit {...props}>
	<SimpleForm>
		<NumberField source="id"/>
		<TextInput source="partNumber"/>
		<TextInput source="category"/>
	    <TextInput source="itemAttributes"/>
	    <BooleanInput source="isDef" label="Is default"/>
	    <NumberInput source="packSize"/>
	    <TextInput source="filtTakbekladnad" label="Filter Takbeklädnad"/>
	    <TextInput source="filtTakfarg" label="Filter Takfärg"/>
	    <TextInput source="filtTillvarkare" label="Filter Tillvärkare"/>
	    <TextInput source="filtModell" label="Filter Modell"/>
	    <TextInput source="filtFals" label="Filter Fals"/>
	    <TextInput source="filtUnderlag" label="Filter Underlag"/>
	    <TextInput source="filtDrainfarg" label="Filter dräneringsfärg"/>
	    <TextInput source="filtSakerhetFarg" label="Filter Säkerhet Färg"/>
		<ArrayInput source="qtyForIncline">
	        <SimpleFormIterator>
				<NumberInput source="id" label="id"/>
			    <NumberInput source="inclineLowerDeg" label="Incline lower degree"/>
			    <NumberInput source="inclineHigherDeg" label="Incline higher degree"/>
			    <NumberInput source="qtyPerMOrM2" label="Quantity per M Or o"/>
			    <TextInput source="whenAttSel" label="When Attribute Selected"/>
			    <TextInput source="whenFilterSel" label="When Filter Selected"/>
			</SimpleFormIterator>
		</ArrayInput>
		
	</SimpleForm>
  </Edit>
);

export const RoofcrudAdd = props => (
 <Create title='Create a Post' {...props}>
  <SimpleForm>
   <TextInput source="partNumber"/>
   <TextInput source="category"/>
   <TextInput source="itemAttributes"/>
   <BooleanInput source="isDef" label="Is default"/>
   <NumberInput source="packSize"/>
   <TextInput source="filtTakbekladnad" label="Filter Takbeklädnad"/>
   <TextInput source="filtTakfarg" label="Filter Takfärg"/>
   <TextInput source="filtTillvarkare" label="Filter Tillvärkare"/>
   <TextInput source="filtModell" label="Filter Modell"/>
   <TextInput source="filtFals" label="Filter Fals"/>
   <TextInput source="filtUnderlag" label="Filter Underlag"/>
   <TextInput source="filtDrainfarg" label="Filter dräneringsfärg"/>
   <TextInput source="filtSakerhetFarg" label="Filter Säkerhet Färg"/>
  </SimpleForm>
 </Create>
);
