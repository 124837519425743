import React from 'react';

function Config() {


    //fetch('https://fiwe-configurator.fiwe.se/deck/choices',{method: 'POST'})
    //.then(response => response.json())
    //.then(data => console.log(data));


    fetch('https://fiwe-configurator.fiwe.se/deck/choices',
    {method: 'POST',
    'Content-Type': 'application/json',
    mode: 'no-cors',
    body: JSON.stringify('{}'),
    'Content-Length': 0})
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    }).catch(err=>{
        console.log(err)
    })

    return (
        <h1>this an example for the configurator</h1>


    );
}

export default Config;