import React from "react";
import { BrowserRouter, Route, Link, Navigate } from "react-router-dom";

function Navbar() {
  return (
    <nav>
      <ul>
        <li>
          <Link reloadDocument to="/admin" replace={true} >Adminverktyg</Link>
        </li>
        <li>
          <Link to="/config">Konfigurator</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;