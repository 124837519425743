import authProvider from './authProvider';
import { createTheme } from '@material-ui/core/styles';
import * as React from "react";
import { fetchUtils, Admin, Resource, ListGuesser, EditGuesser, combineDataProviders } from 'react-admin';
import simpleRestProvider from 'ra-data-json-server';
import {DeckcrudEdit, DeckcrudList, DeckcrudAdd} from './deckings.js'
import {ScrewcrudEdit, ScrewcrudList, ScrewcrudAdd} from './screw.js'
import {RoofcrudEdit, RoofcrudList, RoofcrudAdd} from './roofing.js'

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('optimera_admin_auth_token');
  options.headers.set('Authorization', `Basic ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProviderDecking = simpleRestProvider('/crud/decking', httpClient);
const dataProviderScrew = simpleRestProvider('/crud/screw', httpClient);
const dataProviderRoof = simpleRestProvider('/crud/roofing', httpClient);
const dataProvider = combineDataProviders((resource) => {
	console.log(resource)
    switch (resource) {
        case 'deck':
            return dataProviderDecking;
        case 'screw':
            return dataProviderScrew;
		case 'roof':
            return dataProviderRoof;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});

const OptimeraAdmin = () => {
	return (
		<Admin theme={theme} dataProvider={dataProvider} authProvider={authProvider}>
			<Resource name="deck" list={DeckcrudList} edit={DeckcrudEdit} create={DeckcrudAdd} />
			<Resource name="screw" list={ScrewcrudList} edit={ScrewcrudEdit} create={ScrewcrudAdd} />
			<Resource name="roof" list={RoofcrudList} edit={RoofcrudEdit} create={RoofcrudAdd} />
		</Admin>
	);
}

export default OptimeraAdmin;





